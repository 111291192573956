<template>
  <div>
    <div class="alert alert-info rounded shadow-sm">
      <i class="bi bi-info-circle-fill mr-1"></i> Feel free to explore and
      verify wide range of program offerings from various HEIs in Caraga Region
      that are recognized by CHED.
    </div>
    <div class="card shadow-sm rounded">
      <div class="card-body">
        <form
          @submit.prevent="
            search(DEFAULT_URL, { searchLoader: { enabled: true } })
          "
        >
          <div class="form-row ">
            <div class="col-4">
              <div class="form-group">
                <label for="">Program Name</label>
                <input
                  v-model="params.program_name"
                  type="search"
                  placeholder='Try typing "Nursing" or "Computer"'
                  class="form-control"
                />
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="">HEI</label>
                <select v-model="params.hei_code" class="form-control">
                  <option selected value="">All</option>
                  <option
                    v-for="hei in $store.state.heis"
                    :key="hei.code"
                    :value="hei.code"
                  >
                    {{ hei.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="">Status</label>
                <select
                  v-model="params.status"
                  name=""
                  id=""
                  class="form-control"
                >
                  <option value="">All</option>
                  <option selected value="Active">
                    Active
                  </option>
                  <option value="Inactive">
                    Inactive
                  </option>
                  o
                  <option value="Closed">Closed</option>
                  <option value="Change of Nomenclature"
                    >Change of Nomenclature</option
                  >
                  <option value="Gradual Phase Out">
                    Gradual Phase Out
                  </option>
                  <option value="Phased out">
                    Phased out
                  </option>
                  <option value="Deferred">Deferred</option>
                </select>
              </div>
            </div>
            <div class="col-auto">
              <div class="form-group">
                <label for="" class="invisible">Search Button</label>
                <button
                  type="submit"
                  class="btn btn-primary form-control"
                  style="background-color:#321fdb; color: #fff"
                  :disabled="searchLoader"
                >
                  <span v-if="!searchLoader">Search</span>
                  <span class="spinner-border spinner-border-sm" v-else></span>
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div v-if="programs.length" class="card shadow-sm rounded">
      <div class="card-body">
        <div class="d-flex justify-content-between mt-2">
          <div class="form-inline">
            <div class="form-group">
              <label for="">No. of rows</label>
              <select
                name=""
                id=""
                class="form-control form-control-sm ml-2"
                v-model="params.per_page"
                @change="
                  search(DEFAULT_URL, {
                    searchLoader: { enabled: false },
                  })
                "
              >
                <option value="15">15</option>
                <option value="30">30</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
            </div>
          </div>
          <div class="d-flex justify-content-right">
            <div class="my-auto mr-2">
              <span
                >{{
                  `Showing
              ${pagination.from || 0}-${pagination.to || 0}
                of ${pagination.total || 0} results`
                }}
              </span>
            </div>
            <nav>
              <ul class="pagination mb-0 pagination-sm">
                <li
                  class="page-item"
                  :class="{ disabled: !pagination.prev_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.prev_page_url, {
                        searchLoader: { isEnabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-left"></i
                    ></span>
                    <span class="sr-only">Previous</span>
                  </a>
                </li>

                <li
                  class="page-item"
                  :class="{ disabled: !pagination.next_page_url }"
                >
                  <a
                    class="page-link"
                    href="#"
                    @click.prevent="
                      search(pagination.next_page_url, {
                        searchLoader: { enabled: false },
                      })
                    "
                  >
                    <span aria-hidden="true"
                      ><i class="bi bi-chevron-right"></i
                    ></span>
                    <span class="sr-only">Next</span>
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table table-sm table-bordered table-hover mt-3">
            <thead>
              <th>#</th>
              <th>HEI</th>
              <th>PROGRAM</th>

              <th>PERMIT</th>

              <th>STATUS</th>
            </thead>
            <tbody>
              <tr>
                <td colspan="7" class="text-center" v-if="!programs.length">
                  No results found.
                </td>
              </tr>

              <tr v-for="(program, index) in programs" :key="program.id">
                <td>{{ index + 1 }}</td>
                <td>{{ program.hei_name }}</td>
                <td>
                  {{ program.program_name }}
                  <small
                    v-if="program.former_name"
                    class="form-text text-muted mt-0"
                    >Formerly: {{ program.former_name }}</small
                  >
                </td>

                <td>
                  <button
                    v-if="program.permit_no_file_path"
                    @click.stop="openUrl(program.permit_no_file_path)"
                    class="btn btn-link px-0 py-0"
                  >
                    <i class="bi bi-file-pdf"></i> {{ program.permit_no }}
                  </button>
                  <span v-else>{{ program.permit_no }}</span>
                </td>

                <td>
                  <span
                    class="badge badge-pill"
                    :class="
                      program.status == 'Active'
                        ? 'badge-success'
                        : 'badge-danger'
                    "
                    >{{ program.status }}</span
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Main',
  data() {
    return {
      programs: [],
      searchLoader: false,
      params: {
        program_name: '',
        hei_code: '',
        status: 'Active',
        per_page: 15,
      },
      DEFAULT_URL: 'api/hei-programs',
      pagination: {},
      prefix: 'https://drive.google.com/file/d/',
    };
  },
  methods: {
    search(page_url = null, options) {
      this.searchLoader = options.searchLoader.enabled;
      page_url = page_url || this.DEFAULT_URL;

      const params = {
        ...this.params,
      };

      axios
        .get(page_url, {
          params,
        })
        .then((res) => {
          this.searchLoader = false;
          if (!res.data.data.length) toastr.error('No results found', 'Oops!');
          this.makePagination(res.data);
          this.programs = res.data.data;
        })
        .catch((err) => {
          this.searchLoader = false;
        });
    },
    makePagination(data) {
      let pagination = {
        current_page: data.current_page,
        last_page: data.last_page,
        next_page_url: data.next_page_url,
        prev_page_url: data.prev_page_url,
        from: data.from,
        to: data.to,
        total: data.total,
      };

      this.pagination = pagination;
    },

    getHeis() {
      if (this.$store.state.heis.length) {
        return;
      }

      axios
        .get('api/heis')
        .then((response) => {
          this.$store.state.heis = response.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    openUrl(path) {
      const url = this.prefix + path + '/view';
      window.open(url, '_blank');
    },
  },
  mounted() {
    this.getHeis();
  },
};
</script>
